body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-control-zoom{
  position: fixed;
  bottom: 110px;
  left: 0;
  z-index: 2000;
}

/*
.leaflet-control-zoom.leaflet-bar{
  border: none;
}

.leaflet-control-zoom.leaflet-bar > a:first-child,
.leaflet-control-zoom.leaflet-bar > a:last-child{
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: transparent;
}

.leaflet-control-zoom.leaflet-bar > a:first-child{
  margin-bottom: 20px;
}


.leaflet-control-zoom.leaflet-bar > a:first-child > span,
.leaflet-control-zoom.leaflet-bar > a:last-child > span{
  color: transparent;
  border-radius: 50%;
  display: block;
  width: 40px;
  height: 40px;
  background-origin: content-box;
  background-size: contain;
  box-shadow: 1px 1px 3px 1px #6c6c6c;
}

.leaflet-control-zoom.leaflet-bar > a:first-child > span{
  background-image: url("./img/plus.svg");
}
.leaflet-control-zoom.leaflet-bar > a:last-child > span{
  background-image: url("./img/minus.svg");
}

.leaflet-control-zoom.leaflet-bar > a.leaflet-disabled:first-child > span{
  background-image: url("./img/plus-dis.svg");
}
.leaflet-control-zoom.leaflet-bar > a.leaflet-disabled:last-child > span{
  background-image: url("./img/minus-dis.svg");
}
*/
.user--position--icon{
  width: 16px;
  height: 16px;
  background-color: #0078A8;
  border-radius: 100%;
  border: 2px solid white;
  box-shadow: 0 0 4px 3px #aaaaaa;
  box-sizing: border-box;
}

.search--position--icon, .pulse {
  transform: translate(-50%, -50%);
  border-radius: 50%;
  box-sizing: border-box;
  position: relative;
}

.search--position--icon:before, .pulse:before,
.search--position--icon:after, .pulse:after {
  content: '';
  position: absolute;
  left: -20px;
  top: -20px;
  right: -20px;
  bottom: -20px;
  border: 2px solid rgb(255 239 86 / 70%);
  /*background-color: rgb(32 73 45 / 30%);*/
  box-shadow: inset 0 0 5px 7px rgb(255 239 86 / 40%);
  border-radius: 50%;
  animation: animate .8s linear infinite
}

.pulse:after {
  animation-delay: 1s;
}

@keyframes animate {
  0% {
    transform: scale(0.4);
  }
  20% {
    transform: scale(1);
  }
  40%{
    transform: scale(1.6);
  }
  80% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(.4);
  }
}

.user--position--icon{
  z-index: 500;
}

.user--position--icon > div{
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: transparent;
  translate: -16px -16px;
  box-sizing: border-box;
}

.MuiModal-backdrop{
  background-color: rgba(0, 0, 0, 0.3)!important;
}

.gps_icon{
  display: inline-block;
  width: 18px;
  height: 18px;
  position: relative;
}
.gps_icon:after{
  position: absolute;
  content: "";
  top: 2px;
  left: 0;
  width: 18px;
  height: 18px;
  background-image: url("./img/GPS.svg");
  background-repeat: no-repeat;
  background-origin: border-box;
  background-size: contain;
}

.fixed-buttons .MuiIconButton-root{
  position: fixed;
  background-color: #20492d;
  box-shadow: 1px 1px 3px 1px #6c6c6c;
  z-index: 500;
}

.fixed-buttons .MuiIconButton-root:hover{
  background-color: #1f743f;
}

.leaflet-zoom-animated path.leaflet-interactive {
  fill-opacity: 0!important;
}

.about{
  width: 100%;
  background-color: white;
  position: fixed;
  bottom: 0;
  z-index: 3000;
}

.about p{
  text-align: center;
  font-size: 11px;
  margin: 3px 0;
}

.route-start--icon, .route-end--icon{
  width: 30px;
  height: 30px;
  background-color: #f50101;
  border-radius: 50%;
}

button.remove-route{
  position: fixed;
  left:10px;
  bottom:200px;
  background-color:#135030;
  z-index: 700;
}

button.remove-route:hover{
  background-color: #1f743f;
}

button.show-route{
  color: #135030;
  border: 1px solid #135030;
  margin-top: 15px;
}

button.show-route:hover{
  background-color: rgb(171 245 122 / 4%);
  border: 1px solid #135030;
}
